<div class="container">
    <h1 class="title">Dokumentumok</h1>
    <div>
        <h2 class="subtitle">Aktuális dokumentumok</h2>
        <div class="grid-container">
            <div class="grid-item">
                <a href="/assets/sfp_jh_000_12166_2023_mvlsz_2024-02-26_1708938512_1709656687.pdf" target="_blank">
                    <h3>TAO jóváhagyó határozat</h3>
                </a>
            </div>
            <div class="grid-item">
                <a href="/assets/sfp_publikus_12166_20240306_1709708029.pdf" target="_blank">
                    <h3>SFP 1</h3>
                </a>
            </div>
            <div class="grid-item">
                <a href="/assets/sfp_publikus_14023_20240307_1709827048.pdf" target="_blank">
                    <h3>SFP 2</h3>
                </a>
            </div>
        </div>
    </div>
    <h2 class="subtitle">Korábbi dokumentumok</h2>
    <div class="grid-container">
        <div class="grid-item" *ngFor="let item of files">
            <a [href]="item.link" target="_blank">
                <h3>{{ item.title }}</h3>
            </a>
        </div>
    </div>
</div>