<div class="home-container">
    <h3>A weboldalunk jelenleg átalakítás alatt áll, de hamarosan újra elérhető lesz.</h3>
    <h3>Az aktuális információkért keressék fel facebook vagy instagram oldalunkat. Ha kérdése van vegye fel velünk a
        kapcsolatot a vacivizilabdase@gmail.com e-mail címen vagy a +36706370380-as telefonszámon.</h3>
    <hr/>
    <h3><b>Közgyűlés meghívó 2024</b></h3>
    <p>Részletek a dokumentumban: <a href="./../../assets/Meghivo_kozgyules2024.pdf">Meghivo_kozgyules2024.pdf</a></p>
    <hr/>
    <p>Adószám: 18671685-2-13</p>
    <p>Bankszámla sz.: 11742094-20150279</p>
</div>