import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  files = [
    { link: "https://vacivizilabda.hu/wp-content/uploads/2023/04/VAC_2023MUK_SFP_jh.hatarozat.pdf", title: "TAO jóváhagyó határozat 2023/2024" },
    { link: "http://vacivizilabda.hu/wp-content/uploads/2023/04/VAC_2023MUK_SFP_jh.kerelem_publ.pdf", title: "SFP 2023/2024" },
    { link: "http://vacivizilabda.hu/wp-content/uploads/2023/04/sfp_jh_000_10023_2022_mvlsz_2022-03-11_1647005078_1647007450.pdf", title: "TAO jóváhagyó határozat 2022/2023" },
    { link: "http://vacivizilabda.hu/wp-content/uploads/2023/04/sfp_publikus_10023_20220317_1647500720.pdf", title: "SFP 2022/2023" },
    { link: "http://vacivizilabda.hu/wp-content/uploads/2023/04/sfp_jh_000_08023_2021_mvlsz_2021-04-07_1617779172_1617957388.pdf", title: "TAO jóváhagyó határozat 2021/2022" },
    { link: "http://vacivizilabda.hu/wp-content/uploads/2023/04/sfp_publikus_08023_20210410_1618030073.pdf", title: "SFP 2021/2022" },
    { link: "http://vacivizilabda.hu/wp-content/uploads/2023/04/SFP_jovahagyo_hatarozat2020_2021.pdf", title: "TAO jóváhagyó határozat 2020/2021" },
    { link: "http://vacivizilabda.hu/wp-content/uploads/2023/04/SFP_2020_2021.pdf", title: "SFP 2020/2021" }
  ];

  constructor() { }

  ngOnInit(): void {
  }
}
