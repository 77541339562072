<!-- header.component.html -->
<header>
    <div class="weboldal-cime">
        <img height="200px" src="/assets/logo.png" />
        <h1>{{ title }}</h1>
    </div>
    <div class="nyari-tabor">
      <h1>NYÁRI TÁBOR 2024</h1>
      <nav>
        <ul>
          <li><a href="https://vac.aquasportclub.hu/nyari-tabor-2024/"><h2>Információ</h2></a></li>
          <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSdjgMRmaglO4GhPZtg9mrLRm0VXqa7j7K5mGScf3a55HVkImQ/viewform"><h2>Jelentkezés</h2></a></li>
        </ul>
      </nav>
    </div>
    <nav>
      <ul>
        <li><a routerLink="/">Főoldal</a></li>
        <li><a routerLink="/documents">Dokumentumok</a></li>
      </ul>
    </nav>
  </header>
  