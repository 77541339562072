import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DocumentsComponent } from './documents/documents.component';

export const routes: Routes = [
  { path: '', component: HomeComponent }, // Főoldalra mutató útvonal
  { path: 'documents', component: DocumentsComponent }, // Dokumentumok oldalra mutató útvonal
  { path: '**', redirectTo: '', pathMatch: 'full' } // Alapértelmezett útvonal
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
